import React from 'react'
import classNames from 'classnames';
import ReactFullpage from '@fullpage/react-fullpage';
import animate from './assets/css/animate.min.css';
import './App.css';
import logoWhite from './assets/logo.jpg'
import logoColor from './assets/logoColor.jpg'
import businessImg from './assets/business.png'
import teamChain from './assets/team/chain.svg'
import teamInternet from './assets/team/internet.svg'
import teamKnow from './assets/team/know.svg'
import mpCode from './assets/mpCode.png'
import appletCode from './assets/appletCode.png'
import address from './assets/address.png'

export default class extends React.Component {
  state = {
    navs: [
      '首页',
      '关于我们',
      '业务模式',
      '产品服务',
      '团队优势',
      '联系我们'
    ],
    currentNav: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll=(event)=>{
    const { currentNav } = this.state
    //滚动条高度
    let clientHeight = document.documentElement.clientHeight; //可视区域高度
    let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
    const nav = parseInt(scrollTop / clientHeight)
    if (currentNav !== nav) {
      this.setState({
        currentNav: nav
      })
    }
  }

  onLeave(origin, destination, direction) {
    const { currentNav } = this.state
    if (currentNav !== destination.index) {
      this.setState({
        currentNav: destination.index
      })
    }
  }

  render () {
    const { navs, currentNav } = this.state
    const animation = classNames({
      [animate.animated]: true,
      [animate.fadeInUp]: true
    })

    const Fullpage = (
      <ReactFullpage
        anchors={['0','1','2','3','4','5']}
        onLeave={this.onLeave.bind(this)}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className={`section index`}>
                <div key="slogan" className={`${ currentNav === 0 ? animation : ''} slogan`} >輕松买卖，放心閱讀</div>
                <div key="sloganEnglish" className={`${ currentNav === 0 ? animation : ''} sloganEnglish`}>Easy Business, Easy Reading</div>
              </div>
              <div className={`section about`}>
                <div className={`${ currentNav === 1 ? animation : ''}`}>
                  <div className="title">关于我们</div>
                  <div className="description">
                    <div>爱读客，成立于2019年7月，是杭州爱读客科技有限公司旗下专注于旧书回收和再销售的公司。</div>
                    <div className="finance">已有百万用户使用我们的产品。</div>
                  </div>
                </div>
              </div>
              <div className={`section business`}>
                <div className={`${ currentNav === 2 ? animation : ''}`}>
                  <div className="title">业务模式</div>
                  <img className="businessImg" src={businessImg} alt="" />
                </div>
              </div>
              <div className={`section productPage`}>
                <div className={`${ currentNav === 3 ? animation : ''}`}>
                  <div className="title">产品服务</div>
                  <div className="products">
                    <div className={`${ currentNav === 3 ? animation : ''} product`} style={{ animationDelay: '.3s' }}>
                      <div className="name">回收</div>
                      <div className="desc">深度介入交易环节，提供高效可靠的旧书回收体验，全国免费上门，轻松省力。</div>
                      <div className="phoneCode" />
                    </div>
                    <div className={`${ currentNav === 3 ? animation : ''} product`} style={{ animationDelay: '.5s' }}>
                      <div className="name">零售</div>
                      <div className="desc">
                        <div style={{ marginBottom: '32px' }}>对回收的旧书进行正版鉴定和翻新处理，保证书的品质。</div>
                        <div>首创仓储式购书，买书如寻宝，更加有趣。</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`section team`}>
                <div className={`${ currentNav === 4 ? animation : ''} title`}>团队优势</div>
                <div className="advantages">
                  <div className={`${ currentNav === 4 ? animation : ''} advantage`} style={{ animationDelay: '.3s' }}>
                    <img src={teamKnow} alt="" />
                    <div className="title">懂二手书</div>
                    <div class="desc">团队创始成员和运营人员，均具有多年创业经验，了解市场和用户。</div>
                  </div>
                  <div className={`${ currentNav === 4 ? animation : ''} advantage`} style={{ animationDelay: '.5s' }}>
                    <img src={teamInternet} alt="" />
                    <div className="title">互联网基因</div>
                    <div class="desc">除了核心团队，还有来自一线互联网公司的电商运营、新媒体运营和设计师。</div>
                  </div>
                  <div className={`${ currentNav === 4 ? animation : ''} advantage`} style={{ animationDelay: '.7s' }}>
                    <img src={teamChain} alt="" />
                    <div className="title">电商和供应链背景</div>
                    <div class="desc">了解电商系统、供应链系统的建设和管理。</div>
                  </div>
                </div>
              </div>
              <div className={`section contactPage`} >
                <div className={`${ currentNav === 5 ? animation : ''}`}>
                  <div className="title">联系我们</div>
                  <div className={`${ currentNav === 5 ? animation : ''} cardWrap`} style={{ animationDelay: '.3s' }}>
                    <div className="card">
                      <div className="companyName">杭州爱读客科技有限公司</div>
                      <div className="contract">
                        <div className="label">公司地址：</div>
                        <div className="value">杭州市滨江区储仓快杰大楼4层4051</div>
                      </div>
                      <div className="contract">
                        <div className="label">联系电话：</div>
                        <div className="value">13355717765</div>
                      </div>
                      <div className="codes" >
                        <div style={{ marginRight: '60px' }}>
                          <img src={mpCode} alt="" />
                          <div>公众号</div>
                        </div>
                        <div>
                          <img src={appletCode} alt="" />
                          <div>小程序</div>
                        </div>
                      </div>
                    </div>
                    <img className="address" src={address} alt="" />
                  </div>
                  <div className="copyright">
                    <div>Copyright © 2019 Hangzhou Aiduke Technology Co., Ltd. All rights reserved.</div>
                    <div style={{ marginTop: '8px' }}>浙ICP备19039825号-1</div>
                  </div>
                </div>
              </div>
            </ReactFullpage.Wrapper>
          )
        }}
      />
    )

    return (
      <div>
          <div className={`header ${ currentNav !== 0 ? 'scrollHeader' : '' }`}>
            {
              currentNav !== 0 ? <img style={{ width: '90px' }} src={logoColor} alt="" /> : <img style={{ width: '90px' }} src={logoWhite} alt="" />
            }
            <div className={`navs ${currentNav !== 0 ? 'scrollNavs' : ''}`}>
              {
                navs.map((nav, index) => (
                  <a
                    href={`#${index}`}
                    className={classNames({
                      ['nav']: true,
                      ['current']: currentNav === index
                    })}
                  >
                    {nav}
                  </a>
                ))
              }
            </div>
          </div>
          {Fullpage}
      </div>
    );
  }
}
